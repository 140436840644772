<template>
  <base-material-card title="Мои задания">
    <div class="px-6">
      <div style="height: 500px">
        <a-table-f-api
          :api="api"
          :model="model"
          :searchable="false"
          :defaults="{
            sort: { key: 'eventon', order: 'ASC' },
            filters: { status: '0' },
          }"
          @click="onClickRow($event)"
        />
      </div>
    </div>
  </base-material-card>
</template>

<script>
import genModelBase from "@/share/components/libs/genModelBase";

export default {
  mixins: [genModelBase],
  data() {
    return {
      viewName: "caller_manager_events",
    };
  },
  computed: {
    api() {
      return this.$root.api["cardManagerEvent"];
    },
    model() {
      const model = this.generateModel("index", this.viewName);
      return model;
    },
  },
  methods: {
    async onClickRow(e) {
      await this.$axios.p(this.$root.api.cardManagerEvent, {
        id: e.row.id,
        status: 1,
      });
      this.$router.push({
        name: "get-card-id",
        params: { id: e.row.caller_item_id },
      });
    },
  },
};
</script>